<template>
	<div class="andon-call">
		
		<div class="container">
			<div class="title">
				<span class="icon1">
					<i :class="menuIcon"></i>
				</span>
				<span class="txt">{{menuName}}</span>
			</div>
			<div class="btn-container" v-if="isShow1">
				<el-button class="andon-btn" v-for="item in exceptionList1" :type="btnType(item.showColor)" @click="open1(item.exceptionId, item.showColor)">
					{{item.exceptionName}}
				</el-button>
			</div>
			<div class="btn-container" v-else-if="isShow2">
				<el-button class="andon-btn" v-for="item in exceptionList2" :type="btnType(item.showColor)" @click="open2(item.exceptionId, item.showColor)">
					{{item.exceptionName}}
				</el-button>
				<div class="back-container" @click="back()">
					<span>
						<i class="el-icon-back"></i>
					</span>
				</div>
			</div>
			<div class="btn-container" v-else>
				<div>
					<el-button class="andon-btn" v-for="item in exceptionList3" :type="btnType(item.showColor)" @click="open3(item.exceptionId, item.showColor, item.did)">
						{{item.exceptionName}}
					</el-button>
				</div>
				<div class="back-container" @click="back()">
					<span>
						<i class="el-icon-back"></i>
					</span>
				</div>
			</div>
		</div>
		<div class="expand-container">
			<div class="title">
				<span class="icon2">
					<i class="iconfont icon-richscan_icon"></i>
				</span>
				<span class="txt">扫码/刷卡</span>
			</div>
			<el-form label-position="left" label-width="80px" @submit.native.prevent style="padding: 0.625rem;">
				<el-form-item label="文本" class="remain-form-box">
					<el-input v-model="cardNoInput" @keyup.enter.native="checkBlur($event)" clearable></el-input>
				</el-form-item>
				<el-form-item label="提示" class="remain-form-box">
					<el-input v-if="isRight" class="remain-form-item1" type="textarea" readonly v-model="remainInput" rows="10" clearable></el-input>
					<el-input v-else class="remain-form-item2" type="textarea" readonly v-model="remainInput" rows="10" clearable></el-input>
					<!-- <el-input class="remain-form-item" type="textarea" readonly v-model="remain" rows="6" clearable></el-input> -->
				</el-form-item>
			</el-form>
		</div>
		<el-dialog
		  title="是否呼叫异常"
		  :visible.sync="dialogVisible1"
		  width="50%"
		  center>
		  <span>
			  <el-input type="text" v-model="remark" placeholder="呼叫异常请输入备注"></el-input>
		  </span>
		  <span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="confirmCallException()">确 定 呼 叫</el-button>
			<el-button type="danger" @click="cancelCall()">取 消</el-button>
			<el-button @click="checkRecord()">查 看 历 史 解 决 记 录</el-button>
		  </span>
		</el-dialog>
		<el-dialog
		  title="请选择再次呼叫或者取消呼叫"
		  :visible.sync="dialogVisible"
		  width="50%"
		  center>
		  <span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="callExceptionAgain()">再 次 呼 叫</el-button>
			<el-button @click="cancelCallException()">取 消 呼 叫</el-button>
		  </span>
		</el-dialog>
		<el-dialog
		  title="历史解决记录"
		  :visible.sync="dialogVisible2"
		  width="50%"
		  center>
		  <span>
		  	<el-table class="table" style="width: 100%" :data="handleHistoryDetails" height="250" :row-class-name="tableRowClassName" :height="tableHeight">
		  		<el-table-column label="解决时间" min-width="33%" align='center'>
		  			<template slot-scope="scope">
		  				<span>{{scope.row.date}}</span>
		  			</template>
		  		</el-table-column>
		  		<el-table-column label="解决人" min-width="33%" align='center'>
		  			<template slot-scope="scope">
		  				<span>{{scope.row.operator}}</span>
		  			</template>
		  		</el-table-column>
		  		<el-table-column label="明细" min-width="33%" align='center'>
		  			<template slot-scope="scope">
		  				<span>{{scope.row.content}}</span>
		  			</template>
		  		</el-table-column>
		  	</el-table>
		  </span>
		</el-dialog>
		<el-dialog
		  title="解决异常"
		  :visible.sync="dialogVisible3"
		  width="50%"
		  center>
		  <span>
		  	<el-input type="textarea" rows="6" v-model="content" placeholder="解决异常请输入解决记录"></el-input>
		  </span>
		  <span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="confirmHandleException()">是</el-button>
			<el-button @click="concelHandleException()">否</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getExceptionList,
		callException,
		getHistoryList,
		cancelCallException,
		getExceptionListByCardNo,
		handleException
	} from 'network/andonCall'
	export default {
		name: "addonCall",
		data() {
			return {
				userId: window.sessionStorage.getItem("userId"),
				cardNoInput: "",//刷卡号
				remainInput: "",//刷卡后提示文本
				isRight: true,
				bigExceptionId: -1,
				smallExceptionId: -1,
				exceptionList1: [],
				exceptionList2: [],
				exceptionList3: [],
				cardExceptionList: [],
				dialogVisible1: false,
				dialogVisible2: false,
				dialogVisible: false,
				dialogVisible3: false,
				remark: "",
				cardNo: "",
				exceptionType: -1,
				content: "",
				did: -1,
				handleHistoryDetails: [],
				tableHeight: 400,
				isShow1: true,
				isShow2: false,
				isShow3: false,
				btnType: function(val){
					if(val == '1'){
						return 'success'
					}else if(val == '2'){
						return 'warning'
					}else if(val == '3'){
						return 'danger'
					}
				}
			}
		},
		created() {
			this.getExceptionList1();
		},
		props: {
			menuIcon: {
				type: String,
				default: ""
			},
			menuName: {
				type: String,
				default: ""
			}
		},
		methods: {
			
			//异常大类刷新
			updateBig() {
				this.isShow1 = false;
				this.isShow2 = false;
				this.isShow3 = false;
				this.$nextTick(() => {
					this.isShow1 = true;
				})
			},
			//异常小类刷新
			updateSmall() {
				this.isShow1 = false;
				this.isShow2 = false;
				this.isShow3 = false;
				this.$nextTick(() => {
					this.isShow2 = true;
				})
			},
			//异常列表刷新
			updateExceptionList() {
				this.isShow1 = false;
				this.isShow2 = false;
				this.isShow3 = false;
				this.$nextTick(() => {
					this.isShow3 = true;
				})
			},
			//流程卡号输入框失去焦点事件
			checkBlur(e) {
				if(e.target.value == "" || e.target.value == null) {
					this.remain = "请刷卡";
				}else {
					let obj = {
						cardNo: e.target.value,
						deptId: sessionStorage.getItem("stationId"),
						eqId: sessionStorage.getItem("eqId")
					}
					getExceptionListByCardNo(obj).then(res => {
						// console.log(res.data.data);
						this.isRight = true;
						if(res.data.code == 0) {
							this.isRight = true;
							this.remainInput = "卡号："+ e.target.value + res.data.msg;
						}else {
							this.isRight = false;
							this.remainInput = e.target.value + res.data.msg;
						}
						this.exceptionList3 = res.data.data;
						this.updateExceptionList();
						// this.$bus.$emit("getExceptionListByCard",e.target.value, this.cardExceptionList)
					})
				}
			},
			// 获取异常列表--大类
			getExceptionList1() {
				let obj = {
					eqId: sessionStorage.getItem("eqId"),
					deptId: sessionStorage.getItem("stationId"),
					exceptionId: 0
				}
				getExceptionList(obj).then(res => {
					if(res.data.code !== 0)
					return this.$message.success(res.data.msg)
					this.exceptionList1 = res.data.data;
				})
			},
			//获取大类中的异常列表--小类
			getExceptionList2(id) {
				// console.log(id)
				let obj = {
					eqId: sessionStorage.getItem("eqId"),
					deptId: sessionStorage.getItem("stationId"),
					exceptionId: id
				}
				getExceptionList(obj).then(res => {
					// console.log("异常小类列表")
					// console.log(res)
					if(res.data.code !== 0)
					return this.$message.success(res.data.msg);
					this.exceptionList2 = res.data.data;
				})
			},
			
			//呼叫异常
			callException1(id) {
				let obj = {
					exceptionId: id,
					deptId: sessionStorage.getItem("stationId"),
					remark: this.remark,
					userId: this.userId,
					eqId: sessionStorage.getItem("eqId")
				}
				callException(obj).then(res => {
					if(res.data.code == 0) {
						console.log(res.data.data.light);
						this.$message.success(res.data.msg);
						this.getExceptionList2(this.bigExceptionId);
					}else {
						this.$message.error(res.data.msg);
					}
				})
			},
			
			//解决响应异常
			handleException1(type, did) {
				let obj = {
					type: type,
					cardNo: this.cardNoInput,
					did: did,
					eqId: sessionStorage.getItem("eqId"),
					content: this.content
				}
				handleException(obj).then(res => {
					// console.log(res.data.data);
					if(res.data.code == 0) {
						this.$message.success(res.data.msg);
						console.log(res.data.data.light);
						let obj = {
							cardNo: this.cardNoInput,
							deptId: sessionStorage.getItem("stationId"),
							eqId: sessionStorage.getItem("eqId")
						}
						getExceptionListByCardNo(obj).then(res => {
							this.exceptionList3 = res.data.data;
						})
						this.getExceptionList1();
					}else {
						this.$message.success(res.data.msg);
					}
				})
			},
			//点击大类去获取小类的异常列表
			open1(id) {
				this.bigExceptionId = id;
				// this.isShow1 = false
				// this.isShow2 = true
				// this.isShow3 = false
				this.updateSmall();
				this.getExceptionList2(id);
			},
			// 点击小类的按钮进行操作
			open2(id, color) {
				this.smallExceptionId = id
				if(color == 1) {
					this.dialogVisible1 = true;
				}else if(color == 2){
					this.dialogVisible = false;
				}
				else{
					this.dialogVisible = true;
				}
			},
			//表格隔行变色
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (rowIndex % 2 === 1) {
					return 'success-row';
				}
				return '';
			},
			//确定呼叫异常
			confirmCallException() {
				this.callException1(this.smallExceptionId);
				this.getExceptionList2(this.bigExceptionId);
				this.dialogVisible1 = false;
			},
			// 取消呼叫操作
			cancelCall() {
				this.dialogVisible1 = false;
				this.$message.info("已取消呼叫异常");
			},
			//查看异常历史解决记录
			checkRecord() {
				this.dialogVisible2 = true;
				let obj = {
					exceptionId: this.smallExceptionId,
					deptId: sessionStorage.getItem("stationId")
				}
				getHistoryList(obj).then(res => {
					// console.log(res)
					this.handleHistoryDetails = res.data.data;
				})
				this.dialogVisible1 = false;
			},
			//取消呼叫异常
			cancelCallException() {
				// console.log(this.exceptionId)
				let obj = {
					exceptionId: this.smallExceptionId,
					deptId: sessionStorage.getItem("stationId")
				}
				cancelCallException(obj).then(res => {
					// console.log("取消呼叫异常");
					// console.log(res);
					if(res.data.code == 0) {
						this.$message.success("取消呼叫成功！");
						this.getExceptionList2(this.bigExceptionId);
						this.dialogVisible = false;
						console.log(res.data.data.light);
					}else {
						this.$message({
							type: "success",
							error: "取消呼叫失败！"
						})
						this.getExceptionList2(this.bigExceptionId);
						this.dialogVisible = false;
					}
				})
			},
			// 再次呼叫
			callExceptionAgain() {
				this.callException1(this.smallExceptionId);
				this.getExceptionList2(this.bigExceptionId);
				this.dialogVisible = false;
			},
			//刷卡获取的异常列表点击事件
			open3(id, color, did) {
				if(color == 2) {
					this.exceptionType = 2;
					this.did = did;
					this.dialogVisible3 = true;
				}else if(color == 3) {
					this.exceptionType = 1;
					this.did = did;
					this.handleException1(this.exceptionType, this.did);
				}
			},
			confirmHandleException() {
				this.handleException1(this.exceptionType, this.did);
				this.dialogVisible3 = false;
			},
			concelHandleException() {
				this.$message.info("已取消解决异常");
				this.dialogVisible3 = false;
			},
			//点击小类的返回按钮
			back() {
				this.getExceptionList1();
				this.updateBig();
			}
		}
	}
</script>
<style>
	/* 表格隔行变色颜色 */
	.el-table .success-row {
		background: #d9ecff;
	}
	.el-form-item__label {
		font-size: 1.125rem !important;
		color: #000000 !important;
	}
	.remain-form-box .remain-form-item1 .el-textarea__inner {
		font-size: 1.125rem !important;
	    color: green !important;
	}
	.remain-form-box .remain-form-item2 .el-textarea__inner {
		font-size: 1.125rem !important;
	    color: red !important;
	}
</style>
<style lang="scss" scoped>
	$mainBgc: #F4F5FA;
	.el-button--success {
		background-image: linear-gradient(to bottom, #40B33C, #41C04D);
	}
	.el-button--danger {
		background-image: linear-gradient(to bottom, #ff5722, #cf1b1b);
	}
	
	.el-button--warning {
		background-image: linear-gradient(to bottom, #f6d743, #ffd31d);
	}
	.andon-call {
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: scroll;
		position: relative;
		background-color: $mainBgc;
		display: flex;
		border-radius: 0.625rem;
		.container {
			width: 74.37%;
			height: 100%;
			border-radius: 0.625rem;
			position: relative;
			box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,.1);
			background: white;
		}
		.title {
			width: 100%;
			display: flex;
			padding: 0.625rem;
			.icon1, .icon2 {
				display: inline-block;
				width: 2.5rem;
				height: 2.5rem;
				text-align: center;
				line-height: 2.5rem;
				border-radius: 0.625rem;
				margin-right: 1.25rem;
				background:linear-gradient(0deg, rgba(41,187,192,1) 0%, rgba(73,208,206,1) 100%);
				i {
					font-size: 1.375rem;
					font-weight: 500;
					color: white;
				}
			}
			.icon2 {
				background: linear-gradient(0deg,rgba(255,125,29,1) 0%,rgba(255,189,32,1) 100%);
			}
			.txt {
				height: 2.5rem;
				font-size: 1.625rem;
				font-family: PingFang SC;
				font-weight: 500;
				color: rgba(58,132,171,1);
				line-height: 2.5rem;
				letter-spacing: 3px;
			}
		}
		
		.btn-container {
			display: grid;
			grid-template-columns: auto auto auto auto;
			grid-template-rows: auto auto auto auto;
			.andon-btn {
				width: 10rem !important;
				height: 3.125rem !important;
				color: white !important;
				text-align: center !important;
				font-size: 1.375rem !important;
				line-height: 1.75rem !important;
				margin: 1.25rem;
				border-radius: 5rem;
			}
		}
		.back-container {
			position: absolute;
			bottom: 1.25rem;
			right: 1.25rem;
			width: 3.75rem;
			height: 3.75rem;
			border-radius: 50%;
			border: 3px solid #3A84AB;
			color: #3A84AB;
			text-align: center;
			line-height: 3.75rem;
			font-size: 1.875rem;
		}
		.expand-container {
			width: 25.5%;
			height: 100%;
			background: white;
			border-radius: 0.625rem;
			box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,.1);
			margin-left: 1.75rem;
		}
	}
</style>
